import PropTypes from 'prop-types';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes,profileProtectedRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";
import ProfileMiddleware from 'routes/middleware';
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { useEffect } from 'react';

import axios from 'axios';
import { getStatus } from 'store/auth/status/actions';






const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const {loginUser } = useSelector(state => ({
    loginUser:state.Login.user,
  }));
  const dispatch=useDispatch();
  useEffect(()=>{
              const authUser=JSON.parse(localStorage.getItem('authUser'));
              if(authUser){
              const token=authUser.token;
              dispatch(getStatus(token));
              }    
    },[])
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
        {profileProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <ProfileMiddleware>
                <Layout>{route.component}</Layout>
              </ProfileMiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;