import {
    GET_STATUS,
    GET_STATUS_SUCCESS
  } from "./actionTypes"
  
  const initialState = {
    status:null
  }
  
  const Status = (state = initialState, action) => {
    switch (action.type) {
      case GET_STATUS:
        state = {
          ...state,
        }
      case GET_STATUS_SUCCESS:{
        state={...state,status:action.payload.status}
      }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default Status
  