import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormFeedback,
  Label,
  Input
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { captilaizeFirst } from "./PayoutCol";
const SourceModal = (props) => {
  const { isOpen, toggle } = props;
  const [sources, setSources] = useState([""]);
  const [viewMode,setViewMode]=useState(false);
  const [mode,setMode]=useState('edit')
  const onAddSource = () => {
    console.log(validation.values.sources);
    const sourceValues=validation.values.sources;
    const newSources = [...sourceValues, ""];
    setSources(newSources);
  };
  

  const onDeleteSource = (index) => {
    const sourceValues=validation.values.sources;
    const newSources=[...sourceValues.slice(0, index), ...sourceValues.slice(index + 1)];
    setSources(newSources);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      sources: sources
    },
    validationSchema: Yup.object().shape({
      sources: Yup.array().of(
        Yup.string().url("Invalid URL").required("URL is required")
      )
    }),
    onSubmit: async(values) => {
     console.log(values.sources);
     console.log(props.id);
     setViewMode(true);
     await axios.post(`${process.env.REACT_APP_API_URL}/payouts/source?mode=${mode}`,{
      sources:values.sources,
      payout_id:props.id
     })
     window.location.reload();
    }
  });

useEffect(()=>{

  if( props.payout?.status==='pending'){

    if(props.payout?.source_of_referral?.length!==0){
      const allSources = props.payout?.source_of_referral.map((s) => s.url);
      setSources(allSources);
      setViewMode(true);
      setMode('view');
    }
    else{
    setViewMode(false);
    setSources(['']);
    setMode('add');
    }
  }
  else if((props.payout?.status==='accepted' || props.payout?.status==='completed') && props.payout?.source_of_referral?.length>0){
    console.log('Hi');
    const allSources = props.payout?.source_of_referral.map((s) => s.url);
    setSources(allSources);
    setViewMode(true)
    setMode('view');
  }
  else if(props.payout?.status==='rejected'){
    if(props.payout?.source_of_referral?.length!==0){
      const allSources = props.payout?.source_of_referral.map((s) => s.url);
      setSources(allSources);
      setViewMode(false);
      setMode('edit');
    }
    else{
    setViewMode(false);
    setMode('edit');
    }
  }
  else{
    setViewMode(false);
    setMode('add');
  }
},[props && props?.payout && props.payout.status])
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{captilaizeFirst(mode)} Source</ModalHeader>
        <Form
          className="repeater"
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <p className="mb-4">
                      {!viewMode && <span className="text-primary">
                        Please include your website, YouTube, or social network
                        links for verification.
                      </span>
                      }
                    </p>

                    {sources.map((source, index) => (
                      <Row key={index}>
                        <Col lg={10} className="mb-3">
                          <Label for={index}>Source {index + 1}</Label>
                          <Input
                            name={`sources[${index}]`}
                            id={index}
                            className="form-control"
                            placeholder="Enter Source Url"
                            type="text"
                            readOnly={viewMode ? true: false}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sources[index] || ""}
                            invalid={
                              validation.touched.sources &&
                              validation.touched.sources[index] &&
                              validation.errors.sources &&
                              validation.errors.sources[index]
                                ? true
                                : false
                            }
                          />
                          {validation.touched.sources &&
                          validation.errors.sources &&
                          validation.errors.sources[index] ? (
                            <FormFeedback type="invalid">
                              {validation.errors.sources[index]}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        {!viewMode && <Col lg={2} className="align-self-center">
                          <div className="d-grid">
                            <input
                              type="button"
                              className="btn btn-primary"
                              value="Delete"
                              onClick={() => onDeleteSource(index)}
                            />
                          </div>
                        </Col>}
                      </Row>
                    ))}
                    {!viewMode && <Row>
                      <Col lg={2}>
                        <input
                          type="button"
                          className="btn btn-success mt-3 mt-lg-0"
                          value="Add"
                          onClick={onAddSource}
                        />
                      </Col>
                    </Row>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {!viewMode && <Button type="submit" color="secondary">
              Submit
            </Button>}
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  );
};

SourceModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default SourceModal;
