import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";




const API_URL = process.env.REACT_APP_API_URL;
const axiosApi = axios.create({
  baseURL: API_URL,
});





axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response  && (error.response.status === 401 || error.response.status === 500)) {
      window.location.href = '/logout';
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  let headers = {
    'Content-Type': 'application/json' 
  };
  if (config.token) {
    headers['Authorization'] = `Bearer ${config.token}`;
  }
  return await axiosApi
    .get(url, {headers})
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  let headers = {
    'Content-Type': 'application/json' 
  };
  if (config.token) {
    headers['Authorization'] = `Bearer ${config.token}`;
  }

  return axiosApi
    .post(url, data, { headers })
    .then((response) => response.data)
}


export async function put(url, data, config = {}) {
  let headers = {
    'Content-Type': 'application/json' 
  };
  if (config.token) {
    headers['Authorization'] = `Bearer ${config.token}`;
  }
  return axiosApi
    .put(url, { ...data }, {headers})
    .then((response) => response.data);
}
export async function patch(url, data, config = {}) {
  let headers = {
    'Content-Type': 'application/json' 
  };
  if (config.token) {
    headers['Authorization'] = `Bearer ${config.token}`;
  }
  return axiosApi
    .patch(url, { ...data }, {headers})
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  let headers = {
    'Content-Type': 'application/json' 
  };
  if (config.token) {
    headers['Authorization'] = `Bearer ${config.token}`;
  }
  return await axiosApi
    .delete(url, {headers})
    .then((response) => response.data);
}
