import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge,Button } from 'reactstrap';


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(date).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

export const captilaizeFirst= str => {
    return (
        str === "" || str === undefined ? "" : str.charAt(0).toUpperCase() + str.slice(1)
    );
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const PayoutId = (cell) => {
    return(
    <Link to="#" className="text-body fw-bold">{cell.value ? cell.value :''}</Link>
    );
}

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const PayoutGenerateDate = (cell) => {
    return cell.value ? formateDate(cell.value) : '';
};

const Sales = (cell) => {
    return cell.value ? cell.value : 0;
};
const PayoutAmount = (cell) => {
    return cell.value ? "₹ "+cell.value : '';
};
const TotalSubscriptionAmount = (cell) => {
    return cell.value ? "₹ "+cell.value : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value === "accepted" ? "success" : cell.value === "pending" ? "warning" : cell.value==="completed"? "info" :"danger")}
        >
            {captilaizeFirst(cell.value)}
        </Badge>
    );
};
const PaymentMethod = (cell) => {
    return (
        <span>
            {cell.value}
        </span>
    );
};

const SourceButton=(cell)=>{
    return (
       <button>{cell.value}</button> 
    )
}
export {
    OrderId,
    PayoutId,
    BillingName,
    Date,
    Sales,
    PaymentStatus,
    PaymentMethod,
    PayoutGenerateDate,
    PayoutAmount,
    SourceButton,
    TotalSubscriptionAmount
};