import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { CLEANUP, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  Login,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* resetLoginState(){

}
function* loginUser({ payload: { user, history } }) {
  try {
      const response = yield call(Login, {
        email: user.email,
        currentPassword: user.currentPassword,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      if(response.is_email_verified==='0'){
        history('/email-not-verified')
      }
      else if(response.is_email_verified==='1' && response.is_admin_approved==='0'){
        history('/email-not-approved')
      }
      else if(response.is_email_verified==='1' && response.is_admin_approved==='1'){
      history('/dashboard')
      }
  } catch (error) {
    yield put(apiError(error.response.data.error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("profile");
    localStorage.removeItem("status");
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(CLEANUP,resetLoginState);
}

export default authSaga;
