import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_REGISTERATION_DATA,
    GET_SUBSCRIPTION_DATA,
    GET_REFERRAL_CODE,
    CLEANUP
} from "./actionTypes";

export const resetDashboardState=()=>({
    type: CLEANUP,
    payload: null, 
})
export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});


export const getRegisterationData=(email,token)=>({
    type: GET_REGISTERATION_DATA,
    payload:{email,token}
})

export const getSubscriptionData=(email,token)=>({
    type: GET_SUBSCRIPTION_DATA,
    payload:{email,token}
})

export const getReferralCode=(oneplay_user_id,token)=>({
    type: GET_REFERRAL_CODE,
    payload:{oneplay_user_id,token}
})