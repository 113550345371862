import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Authmiddleware = (props) => {
  const user=JSON.parse(localStorage.getItem('authUser'));
  const {loginUser}=useSelector(state=>({
    user:state.Login.user,
  }))
  if(loginUser){
    if(loginUser.is_email_verified!=='1' || loginUser.is_admin_approved!=='1'){
      return (
        <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
      );
    }
  }
  else if (!user || user.is_email_verified!=='1' || user.is_admin_approved!=='1') {
   return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    ); 
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
