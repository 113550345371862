import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { GET_STATUS, RESET_PASSWORD} from "./actionTypes"



import {
 GetStatus
} from "../../../helpers/fakebackend_helper"
import { getStatusSuccess } from "./actions"


function* getStatus({ payload: { token} }) {
  try {
       const response = yield call(GetStatus, {token})
       localStorage.setItem('status',JSON.stringify(response));
      if (response) {
        yield put(
          getStatusSuccess(
            response
          )
        )
      }
    }
   catch (error) {
    console.log(error);
  }
}

export function* watchStatus() {
  yield takeEvery(GET_STATUS,getStatus);
}

function* statusSaga() {
  yield all([fork(watchStatus)])
}

export default statusSaga;
