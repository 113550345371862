import React,{useState} from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

import payment from "../../../assets/images/payments/payment-2.png";
const PayoutsModal = props => {
  const { isOpen, toggle,payout } = props
  const handleDownload = (url) => {
    console.log(url);
  
        const fileName = url.split("/").pop();
        
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.setAttribute("download", fileName);
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
  };
  
 
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Payment Details</ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            Payment System: <span className="text-primary">{payout?.type}</span>
          </p>
          <p className="mb-4">
            Payment System Identifier: <span className="text-primary">{payout?></span>
          </p> */}
            <div className="table-responsive">
                      {payout && <img src={process.env.REACT_APP_PAYMENT_S3_URL+payout.uuid+payout.payout_disbursal_screenshot} alt="" className="payment-ss"/>}
            </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
          {payout && <Button type="button" color="primary" onClick={()=>handleDownload(process.env.REACT_APP_PAYMENT_S3_URL + payout.uuid + payout.payout_disbursal_screenshot)}>
            Download
          </Button>}
        </ModalFooter>
      </div>
    </Modal>
  )
}

PayoutsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PayoutsModal
