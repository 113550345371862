import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import {
  Register
} from "../../../helpers/fakebackend_helper"



// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user,history } }) {
  console.log("using the following url for registration: ")
  try 
  {
      const response = yield call(Register, user)
      yield put(registerUserSuccessful(response))
      localStorage.setItem('user',JSON.stringify(user));
      history('/email-verification')
    
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error.response.data.error))
  }
  }


export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
