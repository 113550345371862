import {CLEANUP,GET_PROFILE,PROFILE_ERROR, GET_PROFILE_SUCCESS, UPDATE_PROFILE,UPDATE_PROFILE_SUCCESS,UPDATE_USER_PAYMENT, UPDATE_USER_PASSWORD } from "./actionTypes";

const initialState = {
  user:"",
  error: "",
  success: "",
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case CLEANUP:
      state=initialState;
      break;
    case GET_PROFILE:
      state = { ...state};
      break;
    case UPDATE_PROFILE:
      state = { ...state };
      break;
    case GET_PROFILE_SUCCESS:
      state = { ...state, user:action.payload,success:null,error:null};
      break;
    case UPDATE_PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case UPDATE_USER_PAYMENT:
      state={...state}
    case UPDATE_USER_PASSWORD:
      state={...state}
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Profile;
