import React, { useState,useEffect } from "react";

import {
  Col,
  Row,
  Container,
} from "reactstrap"


import Breadcrumbs from "../../components/Common/Breadcrumb"
import UserProfile from "./UserProfile";
import Payment from "./Payment"
import UpdatePassword from './UpdatePassword'
import {getStatus, getUserProfile} from "../../store/actions"
import {useSelector,useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Profile = () => {

  //meta title
  document.title = "Profile | OnePlay"
  
  const [passwordChanged,setPasswordChanged]=useState(false);
  const [submit,setSubmit]=useState(false);
  const authUser=JSON.parse(localStorage.getItem('authUser'));
  const dispatch=useDispatch();
      const {error,success,user } = useSelector(state => ({
        success:state.Profile.success,
        error: state.Profile.error,
        user:state.Login.user,
      }));


      useEffect(()=>{
        let token;
        if(user){
          token=user.token;
          dispatch(getUserProfile(user.email,token))
        }
        else{
          const authUser=JSON.parse(localStorage.getItem('authUser'));
          token=authUser.token;
          dispatch(getUserProfile(authUser.email,token))
        }
        },[])
    
        useEffect(()=>{
          if(success && success!==null)
          {
            let token;
            if(user){
              token=user.token;
              dispatch(getUserProfile(user.email,token))
            }
            else{
              const authUser=JSON.parse(localStorage.getItem('authUser'));
              token=authUser.token;
              dispatch(getUserProfile(authUser.email,token))
            }
            dispatch(getStatus(token));
          }
          },[success])
        useEffect(()=>{
          if(error && error!==null)
          {
            let token;
            if(user){
              token=user.token;
              dispatch(getUserProfile(user.email,token))
            }
            else{
              const authUser=JSON.parse(localStorage.getItem('authUser'));
              token=authUser.token;
              dispatch(getUserProfile(authUser.email,token))
            }
          }
        },[error])

          useEffect(()=>{
            if(success && success!==null){
            toast(success.success)
            }
            },[success])
      
            useEffect(()=>{
            if(error && error!==null){
            toast.error(error);
            }
            },[error])

  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Profile" breadcrumbItem="Profile" />
          <Row>
            <Col>
              <UserProfile/>
            </Col>
          </Row>

          <Row>
            <Col>
              <Payment/>
            </Col>
          </Row>
          <Row>
            <Col>
              <UpdatePassword/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile
