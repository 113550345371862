import { GET_PROFILE,GET_PROFILE_SUCCESS,UPDATE_PROFILE,UPDATE_PROFILE_SUCCESS,PROFILE_ERROR,UPDATE_USER_PAYMENT,UPDATE_USER_PASSWORD, CLEANUP} from "./actionTypes"



export const resetState=()=>{
  return {
    type:CLEANUP,
    payload:null
  }
}
export const getUserProfile = (email,token) => {
  return {
    type: GET_PROFILE,
    payload: {email,token},
  }
}

export const getProfileSuccess = user=> {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: user,
  }
}
export const updateUserProfile = (user,token) => {
  return {
    type: UPDATE_PROFILE,
    payload: {user,token},
  }
}
export const updateProfileSuccess = success => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: success,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const updateUserPayment= (user,token) =>{
  return{
    type:UPDATE_USER_PAYMENT,
    payload:{user,token}
  }
}

export const updateUserPassword=(user,token) =>{
  return{
    type:UPDATE_USER_PASSWORD,
    payload:{user,token}
  }
}