import React,{useEffect} from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useSelector } from "react-redux";

import logo from "../../assets/images/logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
const EmailNotVerified = () => {
  const navigate=useNavigate();
  //meta title
  const {user} = useSelector(state => ({
    user:state.Login.user,
  }));
  document.title="Email Not Verified | OnePlay";
  const authUser=JSON.parse(localStorage.getItem('authUser'));
  const handleRegenerateLink=()=>{
    const response=axios.post(`${process.env.REACT_APP_API_URL}/rvl`,{
      uuid:user?user.uuid:authUser.uuid,
    }).then(response => {
      console.log(response.data.message);
        toast(response.data.message);
        setTimeout(()=>{
          navigate('/login')
        },5000);
    })
    .catch(()=> {
       toast('Something went wrong. Please try again');
    });
    
    
    
  }
  

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
      <ToastContainer />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-block text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">OnePlay Partner</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <p className="text-muted">
                        Your email address has not been validated, please check your inbox for the verification link, or Please generate a new verification link.
                        </p>
                        <p className="fw-semibold">Note: The verification link will be valid for 24 hours.</p>
                        <div className="mt-4">
                          <button className="btn btn-success" onClick={handleRegenerateLink}>
                          Regenerate verification Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailNotVerified
