import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { CLEANUP, GET_CHARTS_DATA, GET_REFERRAL_CODE, GET_REGISTERATION_DATA, GET_SUBSCRIPTION_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getWeeklyData,
    getYearlyData,
    getMonthlyData,
    registerationData,
    subscriptionData,
    referralCode
}
    from "../../helpers/fakebackend_helper";

function* resetDashboardState(){
console.log("Dashboard state deleted");
}
function* getChartsData({ payload: periodType }) {
    try {
        var response;
        if (periodType == "monthly") {
            response = yield call(getWeeklyData, periodType);
        }
        if (periodType == "yearly") {
            response = yield call(getYearlyData, periodType);
        }
        if (periodType == "weekly") {
            response = yield call(getMonthlyData, periodType);
        }

        yield put(apiSuccess(GET_CHARTS_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
}

function* getRegisterationData({ payload: {email,token} }){

try{

const response= yield call(registerationData,{email},{token});
yield put(apiSuccess(GET_REGISTERATION_DATA, response));
}
catch (error) {
    yield put(apiFail(GET_REGISTERATION_DATA, error));
}
}

function* getSubscriptionData({ payload: {email,token} }){
try{
    const response= yield call(subscriptionData,{email},{token});
    yield put(apiSuccess(GET_SUBSCRIPTION_DATA, response));
}
catch (error) {
    yield put(apiFail(GET_SUBSCRIPTION_DATA, error));
}
}
function* getReferralCode({ payload: {oneplay_user_id,token} }){
    try{
        const response= yield call(referralCode, {oneplay_user_id},{token});
        yield put(apiSuccess(GET_REFERRAL_CODE, response));
    }
    catch (error) {
        yield put(apiFail(GET_REFERRAL_CODE, error));
    }
}
export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
    yield takeEvery(GET_REGISTERATION_DATA,getRegisterationData);
    yield takeEvery(GET_SUBSCRIPTION_DATA,getSubscriptionData);
    yield takeEvery(GET_REFERRAL_CODE,getReferralCode);
    yield takeEvery(CLEANUP,resetDashboardState)
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
