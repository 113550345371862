import * as Yup from "yup";
import { useFormik } from "formik";
import React,{useEffect} from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    Row,
    Button,
    Label,
    Input,
    FormFeedback
} from "reactstrap";
import {useSelector,useDispatch } from "react-redux";
import {resetState,updateUserProfile} from "../../store/actions"
const UserProfile = () => {
    
    const dispatch = useDispatch();
    const { user,loginUser} = useSelector(state => ({
        user:state.Profile.user,
        loginUser:state.Login.user
      }));
    


    const validation = useFormik({
        enableReinitialize: true,
    
        initialValues: {
          email:user.email || "",
          account_name:user.account_name || user.email,
          full_name:user.full_name || "",
          date_of_birth:user.date_of_birth || "",
          country:user.country || "",
        },
        validationSchema: Yup.object({
            account_name:Yup.string().required('Please enter your account name').min(4, 'Account name must be at least 4 characters')
            .max(100, 'Account name must not exceed 100 characters'),
            full_name:Yup.string().required('Please enter your full name').min(4, 'Full Name must be at least 4 characters')
            .max(255, 'Full Name must not exceed 255 characters'),
            date_of_birth:Yup.string().required('Please select your date of birth'),
            country:Yup.string().required('Please select your country'),
        }),
        onSubmit: (values) => {
            let token;
            if(loginUser){
              token=loginUser.token;
              
            }
            else{
              const authUser=JSON.parse(localStorage.getItem('authUser'));
              token=authUser.token;
            }
            dispatch(updateUserProfile(values,token));
        }
      });

    return (
        <React.Fragment>
        <Card>
            <CardBody>
                <CardTitle className="h4">Profile<span style={{color:'red'}}>*</span></CardTitle>
                <p className="card-title-desc">

                </p>
                <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>

                    <Row className="mb-3">
                        <Label
                            className="col-md-2 col-form-label"
                        >
                            Account Name
                        </Label>
                        <div className="col-md-10">
                            <Input
                                name="account_name"
                                className="form-control"
                                placeholder="Enter account name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.account_name || ''}
                                invalid={validation.touched.account_name && validation.errors.account_name ? true:false}
                            />
                            {validation.touched.account_name && validation.errors.account_name ? (
                          <FormFeedback type="invalid">{validation.errors.account_name}</FormFeedback>
                        ) : null}
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-email-input"
                            className="col-md-2 col-form-label"
                        >
                            Email Address
                        </label>
                        <div className="col-md-10">
                            <input
                                className="form-control"
                                type="email"
                                defaultValue={user.email}
                                readOnly={true}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-xs-12 col-sm-12 col-md-2 col-form-label"
                        >
                            Phone Number
                        </label>
                        <div className="col-3 col-sm-3 col-md-2">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={user.calling_code}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-9 col-sm-8 col-md-8">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={user.mobile}
                                readOnly={true}
                            />
                        </div>

                    </Row>
                    <Row className="mb-3">
                    <Label
                            className="col-md-2 col-form-label"
                        >
                            Full Name
                        </Label>
                        <div className="col-md-10">
                            <Input
                                name="full_name"
                                className="form-control"
                                placeholder="Enter Full Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.full_name || ''}
                                invalid={validation.touched.full_name && validation.errors.full_name ? true:false}
                            />
                            {validation.touched.full_name && validation.errors.full_name ? (
                          <FormFeedback type="invalid">{validation.errors.full_name}</FormFeedback>
                        ) : null}
                        </div>
                    </Row>
                    <Row className="mb-3">
                    
                    
                        <Label
                            className="col-md-2 col-form-label"
                            for="date-input"
                        >
                            Date of Birth
                            </Label>
                            <div className="col-md-10">
                            <Input
                                name="date_of_birth"
                                id="date-input"
                                className="form-control"
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.date_of_birth || ''}
                                invalid={validation.touched.date_of_birth && validation.errors.date_of_birth ? true:false}
                            />
                            {validation.touched.date_of_birth && validation.errors.date_of_birth ? (
                          <FormFeedback type="invalid">{validation.errors.date_of_birth}</FormFeedback>
                        ) : null}
                        </div>
                    </Row>
                    <Row className="mb-3">
                    <Label
                            className="col-md-2 col-form-label"
                        >
                             Country
                            </Label>
                            <div className="col-md-10">
                            <Input
                                name="country"
                                id="country"
                                className="form-control"
                                list="datalistOptions"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country || ''}
                                placeholder="Type to search..."
                                invalid={validation.touched.country && validation.errors.country ? true:false}
                            />
                            <datalist id="datalistOptions">
                            <option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="American Samoa">American Samoa</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Anguilla">Anguilla</option>
<option value="Antarctica">Antarctica</option>
<option value="Antigua and Barbuda">Antigua and Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Aruba">Aruba</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas">Bahamas</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bermuda">Bermuda</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
<option value="Botswana">Botswana</option>
<option value="Brazil">Brazil</option>
<option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
<option value="British Virgin Islands">British Virgin Islands</option>
<option value="Brunei">Brunei</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Cape Verde">Cape Verde</option>
<option value="Cayman Islands">Cayman Islands</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Christmas Island">Christmas Island</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Cook Islands">Cook Islands</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Croatia">Croatia</option>
<option value="Cuba">Cuba</option>
<option value="Curaçao">Curaçao</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="East Timor">East Timor</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Ethiopia">Ethiopia</option>
<option value="Falkland Islands">Falkland Islands</option>
<option value="Faroe Islands">Faroe Islands</option>
<option value="Fiji">Fiji</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="French Polynesia">French Polynesia</option>
<option value="Gabon">Gabon</option>
<option value="Gambia">Gambia</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Gibraltar">Gibraltar</option>
<option value="Greece">Greece</option>
<option value="Greenland">Greenland</option>
<option value="Grenada">Grenada</option>
<option value="Guam">Guam</option>
<option value="Guatemala">Guatemala</option>
<option value="Guernsey">Guernsey</option>
<option value="Guinea">Guinea</option>
<option value="Guinea-Bissau">Guinea-Bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Honduras">Honduras</option>
<option value="Hong Kong">Hong Kong</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran">Iran</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Isle of Man">Isle of Man</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Ivory Coast">Ivory Coast</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jersey">Jersey</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Kosovo">Kosovo</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Laos">Laos</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon">Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libya">Libya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Macau">Macau</option>
<option value="Macedonia">Macedonia</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mayotte">Mayotte</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia">Micronesia</option>
<option value="Moldova">Moldova</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montenegro">Montenegro</option>
<option value="Montserrat">Montserrat</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands">Netherlands</option>
<option value="Netherlands Antilles">Netherlands Antilles</option>
<option value="New Caledonia">New Caledonia</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="Niue">Niue</option>
<option value="North Korea">North Korea</option>
<option value="Northern Mariana Islands">Northern Mariana Islands</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Palestinian territories">Palestinian territories</option>
<option value="Panama">Panama</option>
<option value="Papua New Guinea">Papua New Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Pitcairn Islands">Pitcairn Islands</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Puerto Rico">Puerto Rico</option>
<option value="Qatar">Qatar</option>
<option value="Republic of the Congo">Republic of the Congo</option>
<option value="Réunion">Réunion</option>
<option value="Romania">Romania</option>
<option value="Russia">Russia</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Barthélemy">Saint Barthélemy</option>
<option value="Saint Helena">Saint Helena</option>
<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
<option value="Saint Lucia">Saint Lucia</option>
<option value="Saint Martin">Saint Martin</option>
<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
<option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="São Tomé and Príncipe">São Tomé and Príncipe</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Serbia">Serbia</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra Leone">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Sint Maarten">Sint Maarten</option>
<option value="Slovakia">Slovakia</option>
<option value="Slovenia">Slovenia</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Korea">South Korea</option>
<option value="South Sudan">South Sudan</option>
<option value="Spain">Spain</option>
<option value="Sri Lanka">Sri Lanka</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
<option value="Swaziland">Swaziland</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syria">Syria</option>
<option value="Taiwan">Taiwan</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania">Tanzania</option>
<option value="Thailand">Thailand</option>
<option value="Togo">Togo</option>
<option value="Tokelau">Tokelau</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad and Tobago">Trinidad and Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
<option value="Tuvalu">Tuvalu</option>
<option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Vatican City">Vatican City</option>
<option value="Venezuela">Venezuela</option>
<option value="Vietnam">Vietnam</option>
<option value="Wallis and Futuna">Wallis and Futuna</option>
<option value="Western Sahara">Western Sahara</option>
<option value="Yemen">Yemen</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>

                            </datalist>
                            {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                        </div>
                    </Row>
                    <Row className="justify-content-end">
                        <div>
                            <Button
                                type="submit"
                                color="primary"
                                className="w-md"
                            >
                                Save Profile
                            </Button>
                        </div>
                    </Row>
                </Form>
            </CardBody>
        </Card>
        </React.Fragment>
    )
}
export default UserProfile;

