import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import logo from "../../assets/images/logo.png";

const EmailVerification = () => {


  document.title="Email Verification | OnePlay";
  const user=JSON.parse(localStorage.getItem('user'));
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">OnePlay Partner</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Registration request submitted</h4>
                        <p>
                        You need to verify your email address.
                        <br/>
                        A verification code has been sent to your email address {" "}
                          <span className="fw-semibold">
                            {user && user.email}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
              <p>
        Go back to{" "}
        <Link to="/login" className="font-weight-medium text-primary">
          Login
        </Link>{" "}
      </p>
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EmailVerification
