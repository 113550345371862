import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/guestUser.svg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [username, setusername] = useState("Admin");
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        tog_center();
                      }}
                    >
                      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                      <span>{props.t("Logout")}</span>
                    </button>
                    </DropdownMenu>
      </Dropdown>
                    <Modal
                      isOpen={modal_center}
                      centered={true}
                      style={{width:'380px'}}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Logout</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div className="mt-3 modal-body d-flex flex-column align-items-center justify-content-center">
                        <p className="mb-4 text-primary" style={{fontSize:'15px'}}>
                          Are you sure you want to logout?
                        </p>
                        <div className="d-flex justify-content-center">
                        <Link to="/logout"
                      type="button"
                      className="btn btn-primary"
                    >
                      Logout
                    </Link>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setmodal_center(false);
                      }}
                      style={{marginLeft:'15px'}}
                    >
                      Cancel
                    </button>
                    </div>
                      </div>

                    </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
