import React from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, FormFeedback,Form} from "reactstrap";

import withRouter from "components/Common/withRouter";


const TermsAndConditions = props => {

  //meta title
  document.title = "TermsAndConditions | OnePlay";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        
      </div>
      <div className="account-pages my-5 pt-sm-5">
      <Container>
      <Row className="justify-content-center">
      <Col className="col-11">
      


            <div><h2>Terms of Use</h2></div>
            <div className="mb-4"><h5>Last Updated: 2 May 2024</h5></div>
        <Row>

            <div><p><span className="text-primary font-weight-bold">1. Agreement:</span> By choosing to participate in our Affiliate Program, you are indicating your acceptance and understanding of the terms and conditions outlined herein.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">2. Eligibility:</span> To be eligible for participation in the Affiliate Program, individuals must be aged 18 years or older.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">3. Registration:</span> Prospective affiliates must complete the registration process, which includes agreeing to abide by these terms and conditions.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">4. Promotion:</span> Affiliates are granted authorization to promote our products and services through approved marketing materials and methodologies.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">5. Commissions:</span> Affiliates stand to earn commissions on qualifying sales that are generated through the utilization of their unique referral codes.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">6. Payment:</span>  On the 5th of each month, a report detailing payouts for the previous month will be generated. Payments will be processed within 30 days of report generation. Payment is contingent upon the verification of the referral code. Verification results will be categorized under the status section and will be accessible within 7-10 working days from the date of data submission. In the event of verification failure, the reason will be provided in the remarks field. Failure to successfully verify may lead to delays in payment.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">7. Prohibited Activities:</span>  Affiliates are strictly prohibited from engaging in any unethical or illegal activities while promoting our products and services.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">8. Termination:</span> We reserve the right to terminate an affiliate's participation in the program if they are found to be in violation of any of the terms outlined in this agreement.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">9. Modification:</span> These terms and conditions are subject to change at any time. Affiliates are responsible for staying informed about any updates or modifications to the terms.</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">10. Governing Law:</span> Any disputes arising from the interpretation or implementation of these terms and conditions will be governed by the laws of [Jurisdiction].</p></div>

        </Row>
        <Row>
            <div><p><span className="text-primary">11. Contact:</span> For any inquiries or concerns regarding the Affiliate Program, please reach out to us at <a href="mailto:affiliate@oneplay.in" className="text-primary">affiliate@oneplay.in.</a></p></div>

        </Row>
        <p className="mt-3">By participating in our Affiliate Program, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>
        <p className="mt-4 text-secondary">
        © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
        
        </Col>
        </Row>
        </Container>
        
      </div>
    </React.Fragment>
  );
};

export default withRouter(TermsAndConditions);

