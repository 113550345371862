import React,{useEffect} from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProfileMiddleware = (props) => {
  const user=JSON.parse(localStorage.getItem('authUser'));
  if (!user || user.is_email_verified!=='1' || user.is_admin_approved!=='1') {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  const {info}=useSelector(state=>({
    user:state.Login.user,
    info:state.Status.status
  }))


   if(info){
    if (!info.user_info || !info.payment_info || info.user_info !== '1' || info.payment_info !== '1') {
      // If user_info or payment_info is missing or not equal to '1', redirect to the profile page
      return (
        <Navigate to={{ pathname: "/profile", state: { from: props.location } }} />
      );
    }
  }

    return <React.Fragment>{props.children}</React.Fragment>;
}
export default ProfileMiddleware;
