import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


//import action
import {getRegisterationData,getSubscriptionData,getReferralCode } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData,registerationData,subscriptionData,referralCode,user } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
    registerationData:state.Dashboard.registerationData,
    subscriptionData:state.Dashboard.subscriptionData,
    referralCode:state.Dashboard.referralCode,
    user:state.Login.user
  }));
  const reports = [
    { title: "Registrations", 
    estimation:"Net Registrations till now",
    iconClass: "bx bx-log-in", description: registerationData && registerationData.registerations ,
  text:"Registrations denote the count of users who have successfully signed up for the OnePlay platform using your referral code."
  },
    {
      title: "Sales",
      estimation:"Net Sales till now",
      iconClass: "dripicons-graph-line",
      description: subscriptionData && subscriptionData.sales,
      text:"Sales represent the number of subscriptions purchased by users referred by you."
    },
    { title: "Revenue (INR)", 
    estimation:"Net Revenue generated till now",
    iconClass: "bx bx-money", description: ((subscriptionData && subscriptionData.revenue === null) || (subscriptionData===undefined && subscriptionData?.revenue === undefined)) ? "₹ 0": ("₹ "+subscriptionData?.revenue),
    text:"Revenue refers to the total earnings generated from subscriptions purchased by users."
  },
  ];

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, []);


  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  
  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);



  const dispatch = useDispatch();
  useEffect(() => {
    let token;
        if(user){
          token=user.token;
          dispatch(getRegisterationData(user.email,token));
    dispatch(getSubscriptionData(user.email,token));
    dispatch(getReferralCode(user.oneplay_user_id,token))
        }
        else{
          const authUser=JSON.parse(localStorage.getItem('authUser'));
          
          if(authUser){
            token=authUser?.token;
          dispatch(getRegisterationData(authUser.email,token));
    dispatch(getSubscriptionData(authUser.email,token));
    dispatch(getReferralCode(authUser.oneplay_user_id,token))
          }
        }
  }, [dispatch]);

  //meta title
  document.title = "Dashboard | OnePlay";
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />
           <Row>
            <Col xl="12">
              <WelcomeComp referralCode={referralCode}/>
            </Col>
          </Row>

          <Row>
          
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  
                  <Col md="4" key={"_col_" + key} >
                    <Card className="mini-stats-wid">
                      <CardBody style={{height:'280px'}}>
                            <Row>
                            <Col xl="8" md={8} sm={8} xs={8} lg={8} style={{height:'250px'}} >
                <div className="text-muted">
                  <div className="mb-4">
                    <div className="h4 card-title">{report.title}</div>
                  <p className="badge badge-soft-success font-size-12 me-1">
                        {report.estimation}
                      </p>
                    <h4>{report.description}</h4>
                    <div>
                     {report.text}
                    </div>
                  </div>
                  
  

                </div>
              </Col>
              <Col xl={4} md={4} sm={4} xs={4} lg={4} style={{height:'250px'}} className="d-flex justify-content-center align-items-center">
                          <div className="avatar-container">
                          <div className="avatar-size rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-50"
                                }
                              ></i>
                            </span>
                          </div>
                          </div>
                        </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
            </Row>
           


        </Container>
      </div>

      {/* subscribe ModalHeader */}
      

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
