import React,{useState} from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    Row,
    Button,
    Label,
    Input,
    FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {useDispatch,useSelector } from "react-redux";
import {updateUserPassword} from "../../store/actions"
const UpdatePassword = () => {
  const dispatch = useDispatch();
  const authUser=JSON.parse(localStorage.getItem('authUser'));
  const {loginUser} = useSelector(state => ({
    loginUser:state.Login.user
  }));
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword,setConfirmNewPassword]=useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-./:;<=>?@\^_|~])[A-Za-z\d!#$%&*+,-./:;<=>?@\^_|~]{8,}$/;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email:authUser.email,
      currentPassword:"",
      password:"",
      confirmPassword:"",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please Enter Your Old Password").matches(
        passwordRegex,
        "Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!"
      ),
      password: Yup.string().required("Please Enter Your New Password").matches(
        passwordRegex,
        "Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!"
      ),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password does not match"
        ),
    }),
  }),
    onSubmit: (values) => {
      let token;
            if(loginUser){
              token=loginUser.token;
              
            }
            else{
              token=authUser.token;
            }
      dispatch(updateUserPassword(values,token));
    }
  });
  const handleOldPassword=()=>{
    setOldPassword((prev)=>!prev);
  }
  const handleNewPassword=()=>{
    setNewPassword((prev)=>!prev);
  }
  const handleConfirmNewPassword=()=>{
    setConfirmNewPassword((prev)=>!prev);
  }
    return (
        <React.Fragment>
        <Card>
                <CardBody>
                  <CardTitle className="h4">Reset Password</CardTitle>
                  <p className="card-title-desc">
                    
                  </p>
                  <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                  <Row className="mb-3">
                    <Label
                      className="col-md-2 col-form-label"
                    >
                      Old Password
                    </Label>
                    <div className="col-md-10">
                      <div className="input-group">
                    <Input
                                name="currentPassword"
                                className="form-control"
                                placeholder="Enter Old Password"
                                type={oldPassword ? "text" : "password"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.currentPassword || ''}
                                invalid={validation.touched.currentPassword && validation.errors.currentPassword ? true:false}
                            />
                            <button type="button" onClick={handleOldPassword} className="input-group-text"><i className={`bx ${oldPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                            
                            {validation.touched.currentPassword && validation.errors.currentPassword ? (
                          <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                        ) : null}
                        </div>
                          {!validation.touched.currentPassword || !validation.errors.currentPassword ? (
    <span className="text-primary" style={{fontSize:'80%'}}>Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!</span>
  ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-md-2 col-form-label"
                    >
                     New Password
                    </Label>
                    <div className="col-md-10">
                    <div className="input-group">
                    <Input
                                name="password"
                                className="form-control"
                                placeholder="Enter New Password"
                                type={newPassword ? "text" : "password"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ''}
                                invalid={validation.touched.password && validation.errors.password ? true:false}
                            />
                            <button type="button" onClick={handleNewPassword} className="input-group-text"><i className={`bx ${newPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                            
                            {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                          </div>
                          {!validation.touched.password || !validation.errors.password ? (
    <span className="text-primary" style={{fontSize:'80%'}}>Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!</span>
  ) : null}  

                    </div>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      className="col-md-2 col-form-label"
                    >
                      Confirm New Password
                    </Label>
                    <div className="col-md-10">
                    <div className="input-group">
                    <Input
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Enter Confirm New Password"
                                type={confirmNewPassword ? "text" : "password"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ''}
                                invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true:false}
                            />
                            <button type="button" onClick={handleConfirmNewPassword} className="input-group-text"><i className={`bx ${confirmNewPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                            
                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                    </div>
                    </div>
                  </Row>
                  <Row className="justify-content-end">
                        <div>
                            <Button
                                type="submit"
                                color="primary"
                                className="w-md"
                            >
                                Change Password
                            </Button>
                        </div>
                    </Row>
                    </Form>
                </CardBody>
              </Card>
        </React.Fragment>
    )
}
export default UpdatePassword;

