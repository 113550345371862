import React from "react";
import { Navigate } from "react-router-dom";

import Payouts from "../pages/Payouts/Payouts/index"


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Profile from "../pages/Profile/Profile"
import EmailNotVerified from "pages/AuthenticationInner/auth-email-not-verified";
import EmailNotApproved from "pages/AuthenticationInner/auth-email-not-approved";
import ResetPassword from "pages/Authentication/ResetPassword";
import TermsAndCondition from "pages/TermsAndCondition";

const authProtectedRoutes = [
 
  //profile
  { path: "/profile", component: <Profile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  {path:"/tnc",component:<TermsAndCondition/>},
  {path:"/verify",component:<ConfirmMail />},
  {path:"/email-not-verified",component:<EmailNotVerified/>},
  {path:"/email-not-approved",component:<EmailNotApproved/>},

  { path: "/reset", component: <ResetPassword/> },
  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-recoverpw-2", component: <ForgetPwd2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },



  { path: "/email-verification", component: <EmailVerification /> },
];

const profileProtectedRoutes=[
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/payouts", component: <Payouts/> },
];


export { authProtectedRoutes, publicRoutes,profileProtectedRoutes };
