import React,{useEffect} from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// import images

import logo from "../../assets/images/logo.png";

const EmailNotApproved = () => {

  //meta title
  document.title="Email Not Approved | OnePlay";

  

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-block text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">OnePlay Partner</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <p className="text-muted">
                        Your account is still being set up; you will be notified by email once it is complete. Thank you for your patience.
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailNotApproved
