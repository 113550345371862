import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    Row,
    Button,
    Label,
    Input,
    FormFeedback
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {useSelector,useDispatch } from "react-redux";
import {updateUserPayment} from "../../store/actions"
const Payment = () => {

  const dispatch = useDispatch();
  const authUser=JSON.parse(localStorage.getItem('authUser'));
  const { user,loginUser} = useSelector(state => ({
      user:state.Profile.user,
      loginUser:state.Login.user
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email:authUser.email,
      payment_system:user.payment_system || "",
      payment_system_identifier:user.payment_system_identifier || "",
    },
    validationSchema: Yup.object({
        payment_system:Yup.string().required('Please choose your payment system').oneOf(['PayPal', 'UPI'], 'Payment system must be either PayPal or UPI'),
        payment_system_identifier:Yup.string().required('Please enter your payment system identifier')
    }),
    onSubmit: (values) => {
      let token;
            if(loginUser){
              token=loginUser.token;
              
            }
            else{
              const authUser=JSON.parse(localStorage.getItem('authUser'));
              token=authUser.token;
            }
      dispatch(updateUserPayment(values,token));
    }
  });

    return (
        <React.Fragment>
        <Card>
                <CardBody>
                  <CardTitle className="h4">Payment Information<span style={{color:'red'}}>*</span></CardTitle>
                  <p className="card-title-desc text-primary">
                  To be eligible for the affiliate reward, provide payment details in your profile.
                  </p>
                  <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                  <Row className="mb-3 mt-3">
                  <Label
                            className="col-md-2 col-form-label"
                        >
                             Payment System
                            </Label>
                    <div className="col-md-10">
                    <Input
                                name="payment_system"
                                id="payment-input"
                                className="form-control"
                                list="paymentMode"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.payment_system || ''}
                                placeholder="Type to search..."
                                invalid={validation.touched.payment_system && validation.errors.payment_system ? true:false}
                            />
                      <datalist id="paymentMode">
                        <option value="PayPal" />
                        <option value="UPI" />
                      </datalist>
                      {validation.touched.payment_system && validation.errors.payment_system ? (
                          <FormFeedback type="invalid">{validation.errors.payment_system}</FormFeedback>
                        ) : null}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      for="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      Payment System Identifier
                    </Label>
                    <div className="col-md-10">
                    <Input
                                name="payment_system_identifier"
                                className="form-control"
                                placeholder="Enter Payment System Identifier"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.payment_system_identifier || ''}
                                invalid={validation.touched.payment_system_identifier && validation.errors.payment_system_identifier ? true:false}
                            />
                            {validation.touched.payment_system_identifier && validation.errors.payment_system_identifier ? (
                          <FormFeedback type="invalid">{validation.errors.payment_system_identifier}</FormFeedback>
                        ) : null}
                      <span className="text-muted">e.g "user@okhdfcbank"</span>
                    </div>
                  </Row>
                  <Row className="justify-content-end">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Save Payments
                          </Button>
                        </div>
                    </Row>
                    </Form>
                </CardBody>
              </Card>
        </React.Fragment>
    )
}
export default Payment;

