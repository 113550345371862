import {
    GET_STATUS,
    GET_STATUS_SUCCESS
  } from "./actionTypes"
  
  export const getStatus = (token) => {
    return {
      type: GET_STATUS,
      payload: { token },
    }
  }
  
  export const getStatusSuccess = (status) => {
    return {
      type: GET_STATUS_SUCCESS,
      payload: {status}
    }
  }
  