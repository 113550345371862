import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { RESET_PASSWORD} from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError } from "./actions"


import {

  ResetPwd,
} from "../../../helpers/fakebackend_helper"





//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetUser({ payload: { user, history} }) {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    
      const response = yield call(ResetPwd,`/reset-password/${token}`, {
        password:user.password,
        confirmPassword:user.confirmPassword,
      })
      if (response) {
        yield put(
          userResetPasswordSuccess(
            response.success
          )
        )
        history('/login')
      }
    }
   catch (error) {
    console.log(error);
    yield put(userResetPasswordError(error))
  }
}

export function* watchUserResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetUser)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserResetPassword)])
}

export default resetPasswordSaga
