import React,{useRef} from "react"

import { Row, Col, Card, CardBody,Button } from "reactstrap"
import { ToastContainer, toast } from 'react-toastify';

const WelcomeComp = ({referralCode}) => {
  const referralRef=useRef(null);
  const copyToClipboard=()=>{
    if(referralRef.current){
      navigator.clipboard.writeText(referralRef.current.value).then(() => {
       toast("Referral Code Copied to Clipboard")
      })
      .catch(err => {
        toast("Error in copying Referral code")
      });
    }
  }
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody className="pt-0">
          <Row>

            <Col sm="12">
              <div className="pt-3">
                <Row>
                <Row>
                  <Col xs="3" sm={2} md={2} lg={2} xl={2} className="d-flex justify-content-center align-items-center">
                  <div className="h4 card-title text-muted fw-medium my-1">
                              Referral Code
                            </div>
                            </Col>
                      
                      <Col xs="7" sm={8} md={8} lg={8} xl={8} className="d-flex justify-content-center align-items-center">
                        <input
                          type="text"
                          className="form-control h4 card-title"
                          value={referralCode && referralCode.referral_code}
                          readOnly
                          ref={referralRef}
                          style={{
                            height: '100%',
                            color:'#A83AFE',
                            fontWeight:'500',
                            border:'none'
                          }}
                        />
                        </Col>
                        <Col xs="2" className="d-flex justify-content-center align-items-center">
                          <Button type="button" color="primary" onClick={copyToClipboard}>
                            Copy to Clipboard
                          </Button>
                          <ToastContainer />
                          </Col>
                          </Row>
                          
                  
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
